import React from "react";
import { Trans, useTranslation } from "gatsby-plugin-react-i18next";
import { LinkText } from "../../templates/LinkText";

const WaterHeat = () => {
  const { t } = useTranslation();
  return (
    <section className="services-details-area">
      <div className="container">
        <div className="row">
          <div className="col-lg-12 col-md-12">
            <div className="services-details-desc">
              <span className="sub-title">{t("Wasser & Wärme")}</span>
              <h3>{t("Applications - WaterHeat - Title - 1")}</h3>
              <p>{t("Applications - WaterHeat - Text - 1")}</p>
              <p>{t("Applications - WaterHeat - Text - 2")}</p>

              <h3>{t("Applications - WaterHeat - Title - 2")}</h3>

              <p>{t("Applications - WaterHeat - Text - 3")}</p>
              <ul>
                <li>{t("Applications - WaterHeat - Text - 1_1")}</li>
                <li>
                  <Trans
                    i18nKey="Applications - WaterHeat - Text - 1_2"
                    components={{
                      link_minergieA: (
                        <LinkText
                          to="https://www.minergie.ch/de/zertifizieren/minergie-a/"
                          title="Minergie"
                        />
                      ),
                      link_minergieP: (
                        <LinkText
                          to="https://www.minergie.ch/de/zertifizieren/minergie-p/"
                          title="Minergie"
                        />
                      ),
                    }}
                  />
                </li>
                <li>{t("Applications - WaterHeat - Text - 1_3")}</li>
                <li>{t("Applications - WaterHeat - Text - 1_4")}</li>
                <li>{t("Applications - WaterHeat - Text - 1_5")}</li>
              </ul>

              <h3>{t("Unsere Leistungen")}</h3>
              <p>
                <Trans
                  i18nKey="Applications - WaterHeat - Text - 4"
                  components={{
                    link_meterbilling: (
                      <LinkText to="/meterbilling" title="MeterBilling" />
                    ),
                    link_meter: <LinkText to="/meters" title="Meters" />,
                  }}
                />
              </p>
              <p>{t("Applications - WaterHeat - Text - 5")}</p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default WaterHeat;
