import React from "react";

export const LinkText = (props) => {
  const isInternalLink = props.to.startsWith("/") || props.to.startsWith("#");
  const target = isInternalLink ? "" : "_blank";
  return (
    <a
      href={props.to || "#"}
      target={target}
      rel="noreferrer"
      title={props.title || ""}
    >
      {props.children}
    </a>
  );
};
